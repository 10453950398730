import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Gerador from './pages/gerador-psd';
import {ProdModal, FormModal} from './pages/modal';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={ProdModal} />
        <Route path="/gerador" component={Gerador} />
        <Route path="/codigo" component={FormModal} />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;