import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './styles/global.css';
import './styles/pages/gerador-psd.css';
import './styles/pages/modal-gen-code.css';

import Routes from './routes';

function App() {
  return (
    <Routes />
  );
}

export default App;