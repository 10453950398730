import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Container, Row } from 'react-bootstrap';
import Product from '../components/Product';
import GenCode from '../components/GenCode';

function ProdModal() {  
  return(
    <Container fluid>

      <Modal.Dialog modal-lg="true" className="modal-produtos" >

        <Modal.Header>
          <Modal.Title as="h1">Gerador de Formulários - PSD</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <Row className="psd-modal-row">
            <p>Selecione o produto que você deseja utilizar para criar o formulário: </p>
          </Row>

          <Product />

        </Modal.Body>

      </Modal.Dialog>

    </Container>
  );
}

function FormModal(props: any) {
  const stFields = props.location.state;
  let defaults;
  let selecteds;
  let formconfig;
  let infos;

  stFields.map((field: any) => {
    
    return field.defaults ? 
      defaults = field.defaults 
    : 
    field.selecteds ? 
      selecteds = field.selecteds 
    : 
    field.formconfig ? 
      formconfig = field.formconfig 
    : infos = field.infos; 
  });

  return (
    <Container fluid>
      <Modal.Dialog modal-lg="true" className="modal-generated">
        <Modal.Header>
          <Modal.Title>O Código do formulário foi gerado!</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="gen-modal-row">
            <GenCode defFields={defaults} selectedFields={selecteds} formConfiguration={formconfig} formInfos={infos} />
          </Row>
        </Modal.Body>
      </Modal.Dialog>
    </Container>
  );
}

export {ProdModal, FormModal};