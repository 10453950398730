import { Component } from 'react';

class Integracao extends Component {

  apiCode = () => {
      return (`
      var estados = document.querySelector('select[name="Dropdown"]');
      var cidades = document.querySelector('select[name="cidade"]');
      var escolas = document.querySelector('select[name="escola"]');
      var cargos = document.querySelector('select[name="Dropdown1"]');
      var redes = document.querySelector('select[name="Dropdown2"]');
      var cidadeField = document.querySelector('input[name="SingleLine2"]');
      var escolaField = document.querySelector('input[name="SingleLine1"]');
      var escolaNomeField = document.querySelector('input[name="SingleLine8"]');
      var redesCol = document.querySelector('#redes');
      var isBeingListen = false;
      var estadoChoices = new Choices(estados, {
          itemSelectText: ' '
      });
      var cidadeChoices = new Choices(cidades, {
          itemSelectText: ' '
      });
      var escolaChoices = new Choices(escolas, {
          itemSelectText: ' '
      });
      var redeChoices = new Choices(redes, {
          itemSelectText: 'Selecionar'
      });
      var cargoChoices = new Choices(cargos, {
        itemSelectText: ' '
      });

      var ow = document.querySelector('input[name="SingleLine"]');
      var ty = document.querySelector('input[name="SingleLine3"]');
      var ce = document.querySelector('input[name="SingleLine4"]');
      var co = document.querySelector('input[name="SingleLine5"]');
      var pr = document.querySelector('input[name="SingleLine6"]');
      var ori = document.querySelector('input[name="SingleLine7"]');

      function choicesDestroy(choices) {
          choices.destroy()
      }

      estados.addEventListener('change', function(e) {
        var request = new XMLHttpRequest();

        request.open('GET', 'https://dev.paprica.ag/zoho/listCities.php?estado='+encodeURI(estados.value), true);

        request.onload = function() {
            if (this.status >= 200 && this.status < 400) {
              choicesDestroy(cidadeChoices);
              document.querySelectorAll('select[name="cidade"] option').forEach(element => {
                  element.remove();
              });
              var cityOption = document.createElement("option");
                  cityOption.text = 'Selecione uma cidade';
                  cityOption.value = '';
                  cidades.appendChild(cityOption);
              var data = JSON.parse(this.response);
              Object.keys(data).forEach(function(key) {
                  var cityOption = document.createElement("option");
                  cityOption.text = data[key];
                  cityOption.value = data[key];
                  cidades.appendChild(cityOption);
              });
              cidadeChoices = new Choices(cidades, {itemSelectText: ' '});
              if(!isBeingListen) {
                cidades.addEventListener('change', function(){
                  cidadeField.value = cidades.value;
                  var request = new XMLHttpRequest();

                  request.open('GET', 'https://dev.paprica.ag/zoho/listCities.php?estado='+encodeURI(estados.value)+'&cidade='+encodeURI(cidades.value), true);

                  request.onload = function() {
                    if (this.status >= 200 && this.status < 400) {
                        choicesDestroy(escolaChoices);
                        document.querySelectorAll('select[name="escola"] option').forEach(element => {
                            element.remove();
                        });
                        var escolaOption = document.createElement("option");
                            escolaOption.text = 'Escolha uma escola';
                            escolaOption.value = '';
                            escolas.appendChild(escolaOption);
                        var data = JSON.parse(this.response);
                        Object.keys(data).forEach(function(key) {
                            var escolaOption = document.createElement("option");
                            escolaOption.text = data[key];
                            escolaOption.value = key;
                            escolas.appendChild(escolaOption);
                        });
                        var escolaOption = document.createElement("option");
                            escolaOption.text = 'Outra';
                            escolaOption.value = 'outra';
                            escolas.appendChild(escolaOption);
                        escolaChoices = new Choices(escolas, {itemSelectText: ' ', shouldSort: false});
                    } else {
                        alert('Erro ao pegar a lista de escolas');
                    }
                  };
                  request.onerror = function() {
                      alert('Erro ao conectar ao servidor');
                  };
                  request.send();
                });
                isBeingListen = true;
              }
            } else {
              alert('Erro ao pegar a lista de cidades');
            }
          };

          request.onerror = function() {
            alert('Erro ao conectar ao servidor');
          };

          request.send();
        });
        escolas.addEventListener('change', function(e){
        if(escolas.value !== "outra") {
          redesCol.style.display = 'none';
          redes.required = false;
          escolaField.setAttribute('type', 'hidden');
          escolaNomeField.setAttribute('type', 'hidden');
          escolaNomeField.required = false;
          escolaField.value = escolas.value;
          escolaNomeField.value = escolas.options[escolas.selectedIndex].text;

          var request = new XMLHttpRequest();
          request.open('GET', 'https://dev.paprica.ag/zoho/getSchoolData.php?id='+encodeURI(escolas.value)+'&key=09ie2hf74873h3847ys', true);

          request.onload = function() {
              if (this.status >= 200 && this.status < 400) {
                var data = JSON.parse(this.response);
                ow.value = data['dono'];
                ty.value = data['tipo'];
                ce.value = data['celula'];
                co.value = data['conveniada'];
                pr.value = data['proprietario'];
              }
          };
          
          request.onerror = function() {
            alert('Erro ao conectar ao servidor');
          };

          request.send();

        } else {
            redesCol.style.display = 'block';
            redes.required = true;
            escolaField.value = '';
            escolaNomeField.value = '';
            escolaNomeField.setAttribute('type', 'text');
            escolaNomeField.required = true;
            escolaField.value = escolas.value;
        }
      });
      ori.value = window.location.href; `)
  }

  render() {
      return(
        <script dangerouslySetInnerHTML={{__html: this.apiCode() }} type="text/javascript">
        </script>
      );
  }
}

export default Integracao;