import React, { createElement } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Button } from 'react-bootstrap';
import Integracao from '../utils/integracao';
import PhoneMask from '../utils/phone-mask';
import CustomDropdown from '../utils/custom-dropdown';
import CreateForm from './CreateForm';

class GenCode extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      defaults: props.defFields,
      selecteds: props.selectedFields,
      formconfig: props.formConfiguration,
      infos: props.formInfos,
      enableAlert: false,
    }
  }

  formGen = (defForm: any) => {
    const { selecteds } = this.state;
    const style = CreateForm.prototype.styleForm(this.state.formconfig);
    
    let depsForm = [
      // Dependencias de estilo
      createElement('script', {src: "https://cdn.jsdelivr.net/npm/vanilla-masker@1.2.0/lib/vanilla-masker.min.js"}),
      createElement('link', {rel: "stylesheet", href: "https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"})
    ];
    
    if( selecteds.find((sel: any) => sel.name === 'Estado') && selecteds.find((sel: any) => sel.name === 'Cidade') ) {
      return createElement('div', {},
        depsForm,

        createElement('link', {rel: "stylesheet", href: "https://cdn.jsdelivr.net/npm/choices.js/public/assets/styles/choices.min.css"}),

        createElement('script', {src: "https://cdn.jsdelivr.net/npm/choices.js/public/assets/scripts/choices.min.js"}),

        style,

        defForm,

        // API
        <Integracao />,
        selecteds.find((sel: any) => sel.fieldId === 9) ? <CustomDropdown /> : '',
        <PhoneMask />
      );
    }


    return createElement('div', {}, depsForm, style, defForm, <PhoneMask />, selecteds.find((sel: any) => sel.fieldId === 9) ? <CustomDropdown /> : '',);
  }

  customSetEnabled = (isEnabled: boolean) => {
    this.setState((state: any) => ({
      ...state,
      enableAlert: isEnabled,
    }));
  }

  copyCode = (enabled: boolean) => {
    const btn = document.querySelector('.btn-copy-code') as any;
    const code = document.querySelector('#generated-code') as any;
    const range = document.createRange();
    const selection = window.getSelection() as any;

    // se algo estiver selecionado, remove todos
    if (selection.rangeCount > 0) {
      selection.removeAllRanges();
    }

    if(enabled === false) {
      this.customSetEnabled(!enabled);
    }

    range.selectNode(code);
    selection.addRange(range);
    document.execCommand('copy');
    if( !btn.classList.contains('copied') ) btn.classList.toggle('copied');
  }

  render() {
    // Destruct do state
    const { defaults, selecteds, formconfig, infos, enableAlert } = this.state;
    const defaultForm = CreateForm.prototype.defaultForm(defaults, selecteds, formconfig, infos);
    const getForm = this.formGen(defaultForm);
    const showFormGen = renderToStaticMarkup(getForm);

    return(
      <div id="form-source">
        <pre id="container-code">
          <code id="generated-code">
              { showFormGen }
          </code>
        </pre>
        {
          createElement(Button, { type: 'button', className: 'btn-copy-code', onClick: () => this.copyCode(enableAlert) }, enableAlert === true ? 'Copiado para sua Área de transferência!' : 'Copiar código')
        } 
      </div>
    );
  }
}

export default GenCode;