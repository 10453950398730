import { Component } from 'react';

class CustomDropdown extends Component {

  custom = () => {
      return (`
      var customDropdown = document.querySelector('select[name="custom-dropdown"]');
      var customChoices = new Choices(customDropdown, {
        itemSelectText: ' '
      });

      var customSelected = document.querySelector('input[name="SingleLine11"]');

      customDropdown.addEventListener('change', function(e) {
        customSelected.setAttribute('value', customDropdown.value);  
      }); `)
  }

  render() {
      return(
        <script dangerouslySetInnerHTML={{__html: this.custom() }} type="text/javascript">
        </script>
      );
  }
}

export default CustomDropdown;