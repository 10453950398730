import React, { ChangeEvent, createElement } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { BsChevronDown } from 'react-icons/bs';
import { MdFormatAlignCenter, MdFormatAlignJustify, MdFormatAlignLeft, MdFormatAlignRight } from 'react-icons/md';
import { FaPlus } from "react-icons/fa";
import CreateForm from './CreateForm';

class Setup extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      availables: props.availables,
      defaults: props.defaults,
      selecteds: [],
      formconfig: props.formConfig,
      draggedField: props.draggedField
    }
  }

  componentDidMount() {
    document.addEventListener('load', this.previewReadOnly);
  }

  componentDidUpdate() {
    window.addEventListener('drop', this.previewReadOnly);
  }


  /*----- Funções do Drag'n Drop dos campos selecionáveis -----*/
  onDrag = (event: React.MouseEvent<HTMLDivElement>, fld: any) => {
    event.preventDefault();
    this.setState({
      draggedField: fld
    });
  }

  onDragOver = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
  }

  onDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { selecteds, draggedField, availables } = this.state;
    const inArr = availables.indexOf(draggedField);

    
    
    if(inArr !== -1) {
      this.setState( (state: any) => ({
        selecteds: [...state.selecteds, draggedField],
        availables: availables.filter((field: any) => field.fieldId !== draggedField.fieldId),
        draggedField: {},
      }));

    }
    else {
      this.setState((state: any) => ({
        availables: [...state.availables, draggedField],
        selecteds: selecteds.filter((field: any) => field.fieldId !== draggedField.fieldId),
        draggedField: {},
      }));
            
    }

  }
  /*-----------------------------------------------------------*/


  /*----- Funções STATE dos Campos Padrões e Selecionáveis -----*/
  setDefaultState = (name: any, value: any, fieldId: number, sbFieldId?: number) => {

    // SE houver subField
    if(sbFieldId) {
      return this.setState( (state: any) => ({
        ...state,
        defaults: state.defaults.map( (def: any) => 
          def.fieldId === fieldId ? {
            ...def, 
            subFields: def.subFields?.map( (sub: any) => 
              sub.subFieldId === sbFieldId ? 
              { 
                ...sub, 
                placeholder: value
              }
              : sub
          )} 
          : def
        )
      }));
    }

    // SENÃO, retorna os inputs com o set padrão
    return this.setState( (state: any) => ({
      ...state,
      defaults: state.defaults.map( (def: any) =>
        def.fieldId === fieldId ?  
        { 
          ...def, 
          [name]: value
        }
        : def
      )
    }));

  }

  setCustomChoice = (event: React.MouseEvent<HTMLDivElement>, selField: any) => {
    event.preventDefault();
    let fieldId = selField.fieldId;

    // pegar o ultimo objeto choices, caso o optValues exista
    let lastChoice = selField.optValues.slice().pop().choiceId;
    let newId = lastChoice + 1;

    return this.setState( (state: any) => ({
      ...state,
      selecteds: state.selecteds.map( (sel: any) => 
        sel.fieldId === fieldId ?
        {
          ...sel,
          optValues: [
            ...sel.optValues,
            {
              choiceId: newId,
              content: ''
            }
          ]
        }
        : sel
      )
    }));

  }

  // -= Função State dos Selecionáveis =-
  customSetState = (id: any, name: any, value: any, fieldId?: number, choiceId?: number) => {

    // SE for configuração de layout do formulário/botão
    if(id.includes('config')) {
      return this.setState( (state: any) => ({
        ...state,
        formconfig: {
          ...state.formconfig,
          [name]: value
        }
      }));
    }

    if(name === 'optValues') {

      if(choiceId) {

        return this.setState( (state: any) => ({ 
          ...state,
          selecteds: state.selecteds.map( (sel: any) => 
            sel.fieldId === fieldId ?
            {
              ...sel,
              optValues: sel.optValues.map( (opt: any) => 
                opt.choiceId === choiceId ?
                {
                  ...opt,
                  content: value
                }
                : opt
              )
            }
            : sel
          )
        }));

      }


    }

    // SENÃO, retorna os inputs com o set padrão
    return this.setState( (state: any) => ({
      ...state,
      selecteds: state.selecteds.map( (pr: any) =>
        pr.fieldId === fieldId ?  
        { 
          ...pr, 
          [name]: value 
        }
        : pr
      )
    }));
  }
  /*------------------------------------------------------------*/


  /*----- Funções do gerador -----*/

  // Limpando inputs
  clearInput = (event: React.MouseEvent<HTMLInputElement>) => {
    let target = event.currentTarget;

    return this.customSetState(target.id, target.name, '');
  }

  // Mudando formconfig
  changeConfig = (event: ChangeEvent<HTMLInputElement>) => {
    let target = event.currentTarget;

    return this.customSetState(target.id !== '' ? target.id : target.parentElement?.id, target.name, target.value);
  }

  // Capturando os parametros do field e chamando a customSetState
  changeField = (fieldId: any, event: ChangeEvent<HTMLInputElement>, sbFieldId?: any, choiceId?: any) => {
    let target = event.currentTarget;
    let targetId = target.id;
    let targetName = target.name !== '' ? target.name : target.placeholder;
    let targetValue = target.type !== 'checkbox' ? target.value as any : target.checked;

    targetId === 'defaults' ? this.setDefaultState(targetName, targetValue, fieldId, sbFieldId) : this.customSetState(targetId, targetName, targetValue, fieldId, choiceId);
  }

  // Quebra dos placeholders do gerador
  pholderWrap = (field: any) => {
    let subField = field.subFields;

    if(subField) { 
      // campos nome e sobrenome de edição
      return (
        <Row>
          {
            subField.map((sub: any, k: number) => {
              return (
                createElement(Col, { md: 6, key: k }, 
                  <Form.Control 
                    name={'placeholder'}
                    placeholder={`${sub.placeholder}(Clique para editar)`} 
                    as={sub.type} 
                    custom={false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeField(field.fieldId, e, sub.subFieldId)} 
                  />
                )
              );

            })
          }
        </Row>  
      );
    }
    return (
      <Form.Control 
        name={'placeholder'}
        placeholder={`${field.placeholder}(Clique para editar)`} 
        type={'input'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeField(field.fieldId, e)} 
      />
    );
  }

  // Deixando os campos do Preview ReadOnly
  previewReadOnly = () => {
    let childsPreview = document.getElementById("form-preview")?.querySelector('.form_cadastro')?.getElementsByTagName('*') as any;

    Object.keys(childsPreview).map((childKey) => (
      childsPreview[childKey].tagName !== 'OPTION' ? childsPreview[childKey].readOnly = true : childsPreview[childKey].disabled = true
    ));
  }

  customOpt = (selField: any) => {
    
    if(selField.fieldId === 9) {
      let optValues = selField.optValues;

      if(optValues) {

        return optValues.map( (opt: any) => {
          return(
            <Row className="option-row">

              <Col md={10}>
                <Form.Control
                  id={'input-option'}
                  name={'optValues'}
                  type="text"
                  value={ opt.content ? opt.content : ''}
                  placeholder={`(Clique para editar essa opção)`}
                  className="editLabel"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeField(selField.fieldId, e, undefined, opt.choiceId)}
                />
              </Col>

              <Col md={2}>
                <span
                  id={'add-option'}
                  className="add-icon"
                  onClick={ (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.setCustomChoice(event, selField) }
                >
                  <FaPlus />
                </span>
              </Col>

            </Row>
          );
          
        });

      }

    }

    return;
   
  }
  /*------------------------------*/

  render() {
    const { availables, defaults, selecteds, formconfig } = this.state;
    const { infoProps } = this.props;

    const formats = [
      { value: 'left', icon: MdFormatAlignLeft },
      { value: 'right', icon: MdFormatAlignRight },
      { value: 'center', icon: MdFormatAlignCenter },
      { value: 'justify', icon: MdFormatAlignJustify }
    ]

    // Coluna dos campos disponíveis
    let availableFields = availables.map( (avl: any) => {

      return (
        <div 
          key={avl.fieldId} 
          className="choose-field btn-outline-secondary" 
          onDrop={event => this.onDrop(event)} 
          draggable 
          onDrag={(event) => this.onDrag(event, avl)} onDragOver={(event => this.onDragOver(event))} 
        >
          <p>
            {avl.name}
          </p>
        </div>
      );

    });

    // Coluna dos campos "selecionados"
    let defaultFields = defaults.map( (def: any) => {
      
      return (
        <Form.Group 
          key={def.fieldId} 
          controlId={'defaults'} 
          className="default-field" 
          onDragOver={( (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onDragOver(event) )}
          onDrop={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onDrop(event)}
        >

          <Row>
            <Col sm={12} md={{ span: 8, offset: 4 }} className="title-row">
              <h1>{def.name}</h1>
            </Col>
          </Row>
          
          <Form.Control 
            name={'label'} 
            type="text" 
            value={def.label} 
            placeholder={`${def.name}(Clique para editar)`} 
            className="editLabel" 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeField(def.fieldId, e)} 
          />

          { this.pholderWrap(def) }

          <Form.Check 
            name={'isRequired'} 
            checked={def.isRequired} 
            type="checkbox" 
            className="btn-required" 
            label={'Este é um campo obrigatório?'} 
            onChange={ (e: React.ChangeEvent<HTMLInputElement>) => this.changeField(def.fieldId, e) } 
          />

        </Form.Group>
      );
    });

    let selectedFields = selecteds?.sort( (a: any, b: any) => a.fieldId - b.fieldId ).map( (sel: any) => {
      
      return (
        <Form.Group 
          key={sel.fieldId} 
          controlId={'selecteds'} 
          className="selected-field" 
          draggable 
          onDrag={ (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onDrag(event, sel) } 
          onDragOver={( (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onDragOver(event) )} 
          onDrop={ (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.onDrop(event) } 
        >

          <Row>
            <Col sm={12} md={{ span: 8, offset: 4 }} className="title-row">
              <h1>{sel.name}</h1>
            </Col>
          </Row>
          
          <Form.Control 
            name={'label'}
            type="text" 
            value={sel.label} 
            placeholder={`${sel.name}(Clique para editar)`} 
            className="editLabel" 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeField(sel.fieldId, e)} 
          />

          { this.pholderWrap(sel) }

          
          <Form.Check 
            name={'isRequired'} 
            checked={sel.isRequired} 
            type="checkbox" 
            className="btn-required" 
            label={'Este é um campo obrigatório?'} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.changeField(sel.fieldId, e)} 
          />

          <div className="custom-options-group">{ this.customOpt(sel) }</div>

        </Form.Group>
      );
    });


    return(
      <Row>
        <Col md={7}>
          <Row>
            <Col md={4} id="choose-fields">
              { availableFields }
            </Col>
            <Col md={8} id="selected-fields">
              <Form>
                  {/* Personalização de design do formulário */}
                  <Accordion defaultActiveKey="0">
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0" className="accordion-btn">
                          <BsChevronDown /> Design do Formulário
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                      <Card.Body>
                          <Form.Group controlId={'formconfig'}>
                          <Row>
                              <Col md={6}>
                              <Form.Label>Titulo</Form.Label>
                              </Col>
                              <Col md={6}>
                              <ButtonGroup toggle className="format-group">
                                  { formats.map((format, idx) => (
                                      <ToggleButton
                                        key={idx}
                                        id={'formconfig'}
                                        className="formats-icon"
                                        type="radio"
                                        name={'titleAlign'}
                                        value={format.value}
                                        checked={formconfig.titleAlign === format.value}
                                        onChange={(event) => this.changeConfig(event as any)}
                                      >
                                        <format.icon />
                                      </ToggleButton>
                                  ))}
                              </ButtonGroup>
                              </Col>
                          </Row>
                          <Form.Control name={'title'} className="margin-field" type="text" value={formconfig.title} placeholder="Título do Formulário" as="input" onChange={(event) => this.changeConfig(event as any)} />
                          <Form.Label>Página de saída do Formulário</Form.Label>
                          <Form.Control name={'redirectLink'} type={'text'} value={formconfig.redirectLink} placeholder="Página de saída do Formulário" as="input" onChange={(event) => this.changeConfig(event as any)} />
                          <Row>
                              <Col md={6}>
                              <Form.Label>Fundo do Formulário</Form.Label>
                              <Form.Control name={'background'} type="color" value={formconfig.background} onChange={(event) => this.changeConfig(event as any)} />
                              </Col>
                              <Col md={6}>
                              < Form.Label>Cor da fonte</Form.Label>
                              <Form.Control name={'color'} type="color" value={formconfig.color} onChange={(event) => this.changeConfig(event as any)} />
                              </Col>
                          </Row>
                          </Form.Group>
                          <Form.Group controlId={'formconfig'}>
                          <Form.Label>Botão</Form.Label>
                          <Form.Control name={'btnText'} type="text" placeholder="Texto do botão" value={formconfig.btnText} onChange={(event) => this.changeConfig(event as any)} onClick={(e: React.MouseEvent<HTMLInputElement>) => this.clearInput(e)} />
                            <Row>
                              <Col md={6}>
                                <Form.Label>Fundo do botão</Form.Label>
                                <Form.Control name={'btnBackground'} type="color" value={formconfig.btnBackground} onChange={(event) => this.changeConfig(event as any)} />
                              </Col>
                              <Col md={6}>
                                <Form.Label>Cor da fonte</Form.Label>
                                <Form.Control name={'btnColor'} type="color" value={formconfig.btnColor} onChange={(event) => this.changeConfig(event as any)} />
                              </Col>
                            </Row>
                          </Form.Group>
                      </Card.Body>
                      </Accordion.Collapse>
                  </Card>
                  <Card>
                      <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1" className="accordion-btn">
                          <BsChevronDown /> Campos do formulário
                      </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="1">
                      <Card.Body>
                          { defaultFields }
                          { selectedFields }
                      </Card.Body>
                      </Accordion.Collapse>
                  </Card>
          
                  </Accordion>
              </Form>
            </Col>
          </Row>
        </Col>
        
        <Col md={5}>
          <CreateForm defFields={defaults} selState={selecteds} formState={formconfig} infoProps={infoProps} />
        </Col>
      </Row>
    );
  }
}

export default Setup;