import React, { ChangeEvent } from 'react';
import { Row, Col, Form, Button, Collapse, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import Spe from '../images/spe-white.png';
import Cqta from '../images/cqta-bco.png';
import Pes from '../images/pes-bco.png';

class Product extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      products: [
        {
          productId: 1,
          Name: 'SPE',
          Logo: Spe,
        },
        {
          productId: 2,
          Name: 'Conquista',
          Logo: Cqta,
        },
        {
          productId: 3,
          Name: 'PES',
          Logo: Pes,
        },
      ],

      selected: [
        {
          open: false,
          product: '',
        },
      ],

      Campaign: '',
      FormAction: '',
    };
  }

  clickProduct = (isOpen: boolean, name: string) => {
    const selProduct = document.querySelector(`#btn-${name}`) as any;
    const currentProd = document.querySelector('.active') as any; 

    if(currentProd !== null && currentProd.id !== selProduct.id) {
      currentProd.classList.remove('active')
    }

    selProduct.classList.add('active');

    this.setState({
      selected: { 
        open: !isOpen, 
        product: name,
      }
    });
  }

  changeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.currentTarget.name;
    const value = event.currentTarget.value;

    this.setState( (state: any) => ({
      ...state,
      [name]: value,
    }));
  }

  render() {
    const { products, selected } = this.state;
    let campaign = this.state.Campaign;
    let formAction = this.state.FormAction;

    let prods = products.map((prod: { ProductId: number; Name: string; Logo: string; }) => {
      let enable = false;
      if(selected.product === prod.Name) {
        enable = true;
      }
      return(
        <Col md={4} key={prod.ProductId} className="product-column">

          <Button className="btn-products" id={`btn-${prod.Name}`} onClick={ () => this.clickProduct(selected.open, prod.Name) } aria-controls="example-fade-text" aria-expanded={selected.open} >
            <Image src={prod.Logo} thumbnail />
          </Button>

          <Collapse in={enable}>

            <Card className="modal-card">

              <Card.Body> 
                  <Col md={12}>
                      <Form>

                        <Form.Group className="margin-prod">
                          <Row className="psd-modal-row">
                            <Form.Control name="Campaign" type="text" placeholder="Insira o nome da campanha" value={campaign} onChange={ (e: any) => this.changeInput(e) } />
                          </Row>
                        </Form.Group>

                        <Row className="margin-prod psd-modal-row">
                          Ou
                        </Row>

                        <Form.Group className="margin-prod">
                          <Row className="psd-modal-row">
                            <Form.Control name="FormAction" type="text" placeholder="Insira a URL do formulário no Zoho Forms" value={formAction} onChange={ (e: any) => this.changeInput(e) } />
                          </Row>
                        </Form.Group>

                        <Link to={ { pathname: '/gerador', state: [{ prod }, { campaign }, { formAction }] } }>
                          <Button className="btn-avancar" variant="avancar" type="submit">
                            Avançar
                          </Button>
                        </Link>

                      </Form>
                  </Col>
              </Card.Body>

            </Card>

          </Collapse>

        </Col>
      )
    });

    return(
      [
        <Row>
          {prods}
        </Row>
      ]
    );
  }
}

export default Product;