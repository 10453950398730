import { Component } from 'react';

class PhoneMask extends Component {
    mask = () => {
        return (`
        function inputHandler(masks, max, event) {
            var c = event.target;
            var v = c.value.replace(/D/g, '');
            var m = c.value.length > max ? 1 : 0;
            VMasker(c).unMask();
            VMasker(c).maskPattern(masks[m]);
            c.value = VMasker.toPattern(v, masks[m]);
        }
        var telMask = ['(99) 9999-99999', '(99) 99999-9999'];
        var tel = document.querySelector('#international_PhoneNumber_countrycode');
        VMasker(tel).maskPattern(telMask[0]);
        tel.addEventListener('input', inputHandler.bind(undefined, telMask, 14), false);`)
    }

    render() {
        return(
            <script dangerouslySetInnerHTML={{ __html: this.mask() }} type="text/javascript"></script>
        );
    }
}

export default PhoneMask;