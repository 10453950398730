import React, { createElement } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Col, Row, Form } from 'react-bootstrap';
import createCSS from '../utils/createCSS';

class CreateForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      defaults: props.defFields,
      selecteds: props.selState,
      formconfig: props.formState,
      infos: props.infoProps,
      genCode: false,
    };
  }

  componentDidMount() {
    document.addEventListener('load', this.changedState);

    const checkBox = document.querySelector('#termos');
    const btn = document.querySelector('.btn_form');

    checkBox?.setAttribute('disabled', 'true');
    btn?.setAttribute('disabled', 'true');
  }

  componentDidUpdate() {
    document.addEventListener('input', this.changedState);
    document.addEventListener('drop', this.changedState);
    document.addEventListener('click', this.changedState);

    document.addEventListener('drop', this.verifyOptions);
    document.addEventListener('input', this.verifyOptions);
    document.addEventListener('click', this.verifyOptions);
  }

  verifyOptions = () => {
    const { selecteds } = this.state;

    if(selecteds.length > 0) {

      selecteds.forEach( (selected: any) => {

        if(selected.nameZoho === 'custom-dropdown') {
          const opts = selected.optValues;

          opts.find( (s: any) => s.content === '') ? this.setGenCode(true) : this.setGenCode(false);

        } 

      });

    }

  }

  setGenCode(isDisabled: boolean) {

    return this.setState( (state: any) => ({
      ...state,
      genCode: isDisabled
    }));
  }


  // Estilizando o form 
  styleForm(config: any) {

    // retornando uma tag style com o utils/createCSS passando as configurações de design como 'param'
    return createElement('style', {}, createCSS(config));
  }

  /* 
    Mudando o state conforme a coluna de personalização 
  */
  changedState = () => {
    return this.setState( (state: any) => ({
      ...state,
      defaults: this.props.defFields,
      selecteds: this.props.selState,
      formconfig: this.props.formState
    }));
  }

  /* 
    Pegando os choices do select
  */
  getChoices(selVal: string, opts: [] | string, plHolder: any) {

    if(selVal === 'select' && typeof opts !== 'string') {

      return [
        createElement('option', { value: plHolder, selected: true, disabled: true }, plHolder ),
        opts.map((opt: any, x: number) => {
          return createElement('option', { value: opt.content, key: x }, opt.content);
        })
      ];

    }
    
    return;
  }

  /* 
    Singlelines da API 
  */
  apiFields() {
    let singleLines = [ 'SingleLine', 'SingleLine3', 'SingleLine4', 'SingleLine5', 'SingleLine6', 'SingleLine7'];

    return createElement('div', { style: { display: 'none' }}, 
      singleLines.map((sl, y) => {
          return createElement('input', { type: 'hidden', key: y, name: sl, value: '', fieldType: 1, maxlength: '255' })
      })
    );
  }

  /* 
    Singlelines de cidade e escola 
  */
  apiFieldRSC(nameField: any) {
    var redes = createElement('div', { id: 'redes', style: { display: 'none' } }, 
      createElement('select', { type: 'hidden', name: 'Dropdown2' }, 
        createElement('option', { value: '', selected: true, disabled: true }, 'Selecione a Rede de Ensino'), 
        createElement('option', { value: 'Pública' }, 'Pública'),
        createElement('option', { value: 'Privada' }, 'Privada')
      ),
    );
    if(nameField === 'cidade') {
      return createElement('input', { type: 'hidden', name: 'SingleLine2', value: '', fieldType: 1, maxLength: '255' });
    }

    if(nameField === 'escola') {
      return [
        createElement('input', { type: 'hidden', name: 'SingleLine1', value: '', fieldType: 1, maxLength: '255' }),
        redes,
        createElement('input', { type: 'hidden', name: 'SingleLine8', value: '', fieldType: 1, maxLength: '255', placeHolder: 'Digite o nome de sua escola', className: 'form-control margin-sel' })
      ];
    }

    return;
  }

  /*
    Renderizando os campos Placeholders 
  */
  pholderWrap(selField: any) {
    const customEnabled = selField.type !== 'input' && selField.type !== 'textarea' ? 'custom-select' : 'form-control'; 
    let subField = selField.subFields;

    // Campos de nome e sobrenome do preview e do código
    if(subField) { 
      return(
        <Row>
          {
            subField.map((sub: any, k: number) => {
              return (
                createElement(Col, { sm: 12, md: 6, key: k, className: sub.nameZoho === 'Name_Last' ? 'margin-mob' : '' }, 

                  createElement('input', { type: sub.type, name: sub.nameZoho, maxLength: sub.maxLength, fieldType: sub.fieldType, value: '', placeholder: sub.placeholder, className: 'form-control' })
                )
              )
            })
          }
        </Row>
      );
    }

    // Demais campos do preview e do código
    return (
      createElement(Row, {}, 

        createElement(Col, { md: 12 }, 

          selField.type !== 'select' ?
            createElement(`${selField.type}`, { name: selField.nameZoho, id: selField.name === 'Telefone' ? 'international_PhoneNumber_countrycode' : '', value: '', maxLength: selField.maxLength, fieldType: selField.fieldType, placeholder: selField.placeholder, className: `${customEnabled}` })
          :
            createElement(`${selField.type}`, { name: selField.nameZoho, className: `${customEnabled}`}, this.getChoices(selField.type, selField.optValues, selField.placeholder)),
            this.apiFieldRSC(selField.nameZoho)
        )
      )
    );  
  }

  /* 
    Campos Produto e Campanha 
  */
  infoFields(infos: any) {
    const camp = infos.campaign;
    const prod = infos.productName;

    return ([
      createElement('input', { type: 'hidden', name: 'SingleLine9', value: camp, fieldType: 1, maxLength: '255' }),
      createElement('input', { type: 'hidden', name: 'SingleLine10', value: prod, fieldType: 1, maxLength: '255' })
    ]);
  }

  customSelectField() {
    return(
      createElement('input', { type: 'hidden', name: 'SingleLine11', value: '', fieldType: 1, maxLength: '255' })
    );
  }

  /*
    Form do Preview e base do Gerador
  */
  defaultForm(defaults: any, selecteds: any, formconfig: any, infos: any) {

    const actionLink = infos.formAction ? `${ infos.formAction }/htmlRecords/submit` : 'https://forms.zohopublic.com/editorapositivo/form/FormpadroGeradorAPI/formperma/pJ7HLDUHxVFFjiINQH2MVUn1g-X-QYOj3f3rZgr3hTw/htmlRecords/submit';

    let redirectField = formconfig.redirectLink ? createElement('input', { type: 'hidden', name: 'zf_redirect_url', value: formconfig.redirectLink }) : '';

    return (
      createElement(Container, { className: 'form_cadastro' },

        createElement('form', { action: actionLink, name: 'form', id: 'form', method: 'POST', acceptCharset: 'UTF-8', encType: 'multipart/form-data' }, 

          redirectField,

          createElement( 'h2', {}, formconfig.title ),

          defaults.map( (def: any, i: number) => {
            return( 
              createElement(Form.Group, { key: i }, 
                createElement('label', { className: 'field-label' }, def.label !== '' ? def.label : def.name),
                  this.pholderWrap(def)
              )
            )
          }),

          selecteds?.map( (sel: any, i: number) => {
            return( 
              createElement(Form.Group, { key: i }, 
                createElement('label', { className: 'field-label' }, sel.label !== '' ? sel.label : sel.name),
                this.pholderWrap(sel),
                sel.fieldId === 9 ? this.customSelectField() : ''
              )
            )
          }),

          this.apiFields(),

          this.infoFields(infos),

          createElement(Form.Group, { className: 'form-check-inline' },
            createElement('input', { type: 'checkbox', value: 'Concordo', id: 'termos', className: 'form-check-input', required: 'true' } ),
            createElement('label', { for: 'termos', className: 'form-check-label' }, 'Eu concordo em receber comunicações e ofertas personalizadas de acordo com meus interesses.')
          ),

          createElement(Button, { type: 'submit', className: 'btn_form' }, formconfig.btnText)
        ),
      )
    );
  }

  render() {
    const { defaults, selecteds, formconfig, infos, genCode } = this.state;

    const preview = document.querySelector('#form-preview');
    preview?.setAttribute('disabled', 'true');
    
    return([
      <div id="form-preview">

        { this.styleForm(formconfig) }
        { this.defaultForm(defaults, selecteds, formconfig, infos) }

      </div>,

      <Link to={ { pathname: '/codigo', state: [ { defaults }, { selecteds }, { formconfig }, { infos } ] } }>
        <Button type="submit" className="col-12 btn-gen-code" variant="Gerar Código!" disabled={ genCode } >
          Gerar Código!
        </Button>
      </Link>
    ]);
  }
}

export default CreateForm;