function hexToRGB(hex: string) {
  let val = hex.split(''), r, g, b;

  if(val[0] === '#'){
    val.shift();
  }

  switch (val.length) {
    case 2:
      r = parseInt(val[0].toString() + val[1].toString(), 16);
      g = r;
      b = r;
      break;
  
    case 3:
      r = parseInt(val[0].toString() + val[0].toString(), 16);
      g = parseInt(val[1].toString() + val[1].toString(), 16);
      b = parseInt(val[2].toString() + val[2].toString(), 16);
      break;

    case 6:
      r = parseInt(val[0].toString() + val[1].toString(), 16);
      g = parseInt(val[2].toString() + val[3].toString(), 16);
      b = parseInt(val[4].toString() + val[5].toString(), 16);
      break;

    default:
      break;
  }

  return `${r} ${g} ${b}`
}

function createCSS(props: any) {
  let align = props.titleAlign !== '' ? props.titleAlign : 'left';
  return (`
    .choices {
      margin-bottom: 0;
    }
    .choices__item {
      color: #6c757d;
    }

    .choices__input {
      color: #6c757d;
    }

    .form_cadastro {
      background-color: ${props.background};
      color: ${props.color};
      border-radius: 20px;
      padding: 20px 25px;
      z-index: 1;
      position: relative;
    }

    .form_cadastro h2 {
      text-align: ${ align };
      color: ${props.color}!important;
    }

    .form_cadastro #redes {
      margin-top: 20px;
    }

    .form_cadastro .btn_form {
      background-color: ${props.btnBackground}!important;
      color: ${props.btnColor};
      width: 100%;
      padding: 12px 32px;
      border-radius: 40px;
      border: 0;
      font-weight: bolder;
      height: auto;

      transition: background-color 300ms linear;
    }

    .form_cadastro .btn_form:hover {
      background-color: rgb(${hexToRGB(props.btnBackground)} / 80%)!important;
    }

    .btn.disabled, .btn:disabled {
      opacity: 1;
      cursor: not-allowed;
    }

    .form_cadastro .margin-sel {
      margin-top: 20px;
    }

    @media (max-width: 768px) {
      .margin-mob {
          margin-top: 20px;
      }
    }
  `)
}

export default createCSS;