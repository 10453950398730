import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Panel from '../components/Panel';

function Gerador(props:any) {
  let infoState = props.location.state;
  let product: any;
  let campaign: any;
  let formAction: any;

  infoState.map((i: any) => {
    return i.prod ? product = i.prod : i.campaign ? campaign = i.campaign : formAction = i.formAction;
  });

  let productName = product.Name;
  let logo = product.Logo;

  return(
    <div className="body-gerador">

      <div className={productName}>

        <Col sm={12} md={{ span:6, offset: 3 }} id="logo-header-col">
          <img src={logo} alt="Produto" />
        </Col>

      </div>

      <Container fluid className="content-generator">

        <Row className="pageTitle">
          <h1>Gerador de Formulários</h1>
        </Row>

        <Row className="description">
          <p>Você está criando um formulário para a campanha: <mark className={`mark${productName}`}>{campaign} </mark>. Arraste os campos disponíveis para incluí-los em seu modelo e reorganize-os em: Campos do formulário.</p>
        </Row>

        <Row className="content-page">
          <Col md={12} className="generator-form">
            <Panel infoFields={{ productName, campaign, formAction }} />
          </Col>
        </Row>

      </Container>

      <div className={`footer${productName}`}>
        {productName} © 2021
      </div>
      
    </div>
  );
}

export default Gerador;