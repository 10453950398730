import React from 'react';
import Field from '../interfaces/Field';
import Generator from '../interfaces/Generator';
import Setup from './Setup';

class Panel extends React.Component<Field, Generator> {
  constructor(props: any) {
    super(props);
    this.state = { 
      availables: [
        {
          fieldId: 4,
          nameZoho: 'Dropdown',
          name: 'Estado',
          label: '',
          type: 'select',
          placeholder: 'Selecione seu Estado',
          isRequired: false,
          optValues: [ 
            {content: "AC"}, {content: "AL"}, {content: "AP"}, {content: "AM"},{content: "BA"},{content: "CE"}, {content: "DF"}, {content: "ES"}, {content: "GO"}, {content: "JP"},{content: "MA"}, {content: "MT"}, {content: "MS"}, {content: "MG"}, {content: "PA"}, {content:"PB"}, {content: "PR"},{content: "PE"}, {content: "PI"}, {content: "RJ"}, {content: "RN"},{content: "RS"}, {content: "RO"}, {content: "RR"}, {content: "SC"}, {content: "SP"}, {content: "SE"}, {content: "TO"},
          ]
        },
        {
          fieldId: 5,
          nameZoho: 'cidade',
          name: 'Cidade',
          label: '',
          type: 'select',
          placeholder: 'Selecione sua cidade',
          isRequired: false,
          optValues: [ {content: 'Selecione sua Cidade'} ],
        },
        {
          fieldId: 6,
          nameZoho: 'escola',
          name: 'Nome da Escola',
          label: '',
          type: 'select',
          placeholder: 'Selecione sua escola',
          isRequired: false,
          optValues: [ {content: 'Selecione sua escola'} ],
        },
        {
          fieldId: 7,
          nameZoho: 'Dropdown1',
          name: 'Cargo',
          label: '',
          type: 'select',
          placeholder: 'Selecione seu cargo',
          isRequired: false,
          optValues: [ {content: 'Diretor(a) Administrativo(a)'}, {content: 'Diretor(a) Pedagógico(a)'}, {content: 'Secretário(a)'}, {content: 'Professor(a)'}, {content: 'Coordenador(a) Pedagógico(a)'}, {content: 'Mantenedor(a)'}, {content: 'Diretor(a) geral'}, {content: 'Presidente'}, {content: 'Familiar e/ou responsável pelo(a) aluno(a)'}, {content: 'Aluno(a)'} ]
        },
        {
          fieldId: 8,
          nameZoho: 'MultiLine',
          name: 'Campo de texto 01',
          label: '',
          type: 'textarea',
          placeholder: '',
          maxLength: '65535',
          isRequired: false,
        },
        { 
          fieldId: 9,
          nameZoho: 'custom-dropdown',
          name: 'Custom Select [Insira o nome do campo Selecionavel aqui] ',
          label: '',
          type: 'select',
          placeholder: '',
          isRequired: false,
          optValues: [ { choiceId: 1, content: '' } ],
        }
      ],
      defaults: [
        {
          fieldId: 1,
          name: 'Nome Completo',
          label: '',
          isRequired: true,
          subFields: [
            {
              subFieldId: 1,
              nameZoho: 'Name_First',
              type: 'input',
              fieldType: '7',
              placeholder: 'Nome',
              maxLength: '255'
            },
            {
              subFieldId: 2,
              nameZoho: 'Name_Last',
              type: 'input',
              fieldType: '7',
              placeholder: 'Sobrenome',
              maxLength: '255'
            },
          ],
        },
        {
          fieldId: 2,
          nameZoho: 'Email',
          name: 'E-mail',
          label: '',
          type: 'input',
          fieldType: '9',
          placeholder: 'email@provedor.com.br',
          maxLength: '255',
          isRequired: true,
        },
        {
          fieldId: 3,
          nameZoho: 'PhoneNumber_countrycode',
          name: 'Telefone',
          label: '',
          type: 'input',
          fieldType: '11',
          placeholder: '(99) 99999-9999',
          maxLength: '20',
          isRequired: true,
        },
      ],
      selecteds: [],
      formconfig: {
        title: '',
        background: '#000000',
        color: '#FFFFFF',
        titleAlign: '',
        btnText: 'Enviar',
        btnBackground: '#FFFFFF',
        btnColor: '#000000',
        redirectLink: '',
      },
      draggedField: {
        fieldId: 0,
        nameZoho: '',
        name: '',
        label: '',
        type: '',
        fieldType: '',
        placeholder: '',
        maxLength: '',
        isRequired: false,
      },
    };
  }

  /*----- Renderizando -----*/
  render() {
    const { availables, defaults, formconfig, draggedField } = this.state;
    const { infoFields } = this.props;
    
    return (
      <Setup 
        availables={availables} 
        defaults={defaults}
        formConfig={formconfig} 
        infoProps={infoFields} 
        draggedField={draggedField}
      />
    );
  }
}

export default Panel; 